export const globalVariables = {
  EmptyList: "No data available!",
  ErrorMessage: "Something went wrong",
  LoaderMessage: "Your request is being processed",
  createAccount: "Registration",
  loginAccount: "Login",
  createBtn: "Next",
  startedEnterDetails:
    "For your security and faster verification, please enter your full name exactly as it appears on your legal ID. All fields are required.",
  firstName: "First Name",
  lastName: "Last Name",
  enterfirstName: "Enter your first name",
  enterlastName: "Enter your last name",
  email: "Email",
  enterEmail: "Enter your email",
  password: "Password",
  newPassword: "New Password",
  currentPassword: "Current Password",
  mustHaveAtlease:
    "Password must be 8 characters long and contain one uppercase, one lowercase, one special character and one number",
  confirmPassword: "Confirm New Password",
  confirmPasswordSignUp: "Confirm Password",
  tempPassword: "••••••••",
  alreadyMember: "Already a member?",
  logIn: "Log in",
  sessionExpire: "Your session is expired",
  byCreatingAnAccount: "By creating an account, you agree to our",
  termsConditions: "Terms and Conditions",
  and: "and",
  privacyPolicy: "Privacy Policy",
  emailRequired: "Please enter a valid email address.",
  invalidEmail: "Please enter a valid email address.",
  invalidWebsite: "Please enter a valid website.",
  passwordRequired: "Password is required",
  firstnameRequired: "First Name is required",
  lastnameRequired: "Last Name is required",
  passwordNotMatch: "Passwords does not match",
  welcomeBack: "Welcome back! Please enter your details.",
  forgotPassword: "Forgot password",
  dontHaveAccount: "Don’t have an account yet?",
  signUp: "Sign up",
  resetLink: "Send OTP Code",
  rememberPassword: "Remember your password?",
  sendResetLink: "OTP code will be sent to your registered mail ID.",
  passwordMatch: "The password and confirm password should match",
  // newPassword: "New Password",
  resetPassword: "Reset Password",
  enterNewPassword: "Enter a new password.",
  restPasswordSuccess: "Password reset successful!",
  restPasswordDescription: "Your password has been reset.",
  restPasswordPending: "Password Reset failed",

  // Register Member

  clickToUpload: "Click to upload",
  dragAndDrop: "or drag and drop",
  svgPNG: "PNG, JPG (max. 800x400px)",
  enterFirstName: "Enter first name",
  enterLastName: "Enter last name",
  enterEmailAddress: "Enter email address",
  Street: 'Street',
  enterLocation: "Enter location",
  suite: "apt/suite",
  enterLocationAddress: "Enter Location Address",
  enterPhoneNumber: "Enter phone number",
  enterEmergencyContactName: "Enter emergency contact name",
  enterEmergencyPhoneNumber: "Enter Emergency Phone Number",
  cancel: "Cancel",
  next: "Next",
  login: "Login",
  previous: "Previous",
  firstNameRequired: "Please enter your first name to proceed.",
  lastNameRequired: "Please enter your last name to proceed.",
  locationRequired: "Please enter a valid location address.",
  invalidPhoneNumber: "Please enter a valid phone number.",
  SSNRequired: "Please enter SSN.",
  SSNError: "Please enter a valid SSN",
  phoneRequired: "Please enter a valid phone number.",
  contactNameRequired: "Please enter a valid emergency contact name.",
  contactNumberRequired: "Please enter a valid emergency contact number.",
  citizenship: "Add Citizenship",
  phoneNumber: "PHONE NUMBER",
  DetailsHeading: "Details",
  DetailsDescription:
    "Please provide following details to verify your identity",
  DetailsOrganizationHeading: "Organization Info. All fields are required",
  DetailsTitle: "TITLE (OPTIONAL)",
  DetailsPlaceHolder: "Select Title",
  DobPlaceholder: "MM/DD/YYYY",
  country: "COUNTRY OF RESIDENCE",
  countryPlaceholder: "United States",
  cintizenship: "CITIZENSHIP",
  cintizenshipPlaceholder: "United States",
  PhoneNo: "PHONE NUMBER",
  SSN: "INDIVIDUAL'S SSN",
  Address1: "ADDRESS LINE 1",
  Address1Placeholder: "Address Line 1",
  Address2: "ADDRESS LINE 2",
  Address2Placeholder: "Address Line 2",
  City: "City",
  State: "State/Region",
  Zip: "Zip",
  Country2: "Country",

  // detail validation

  titleRequired: "Please Select the Title",
  DateOfBirth: "Please Enter the Date of Birth",
  Residence: "Please Enter the Country and residence",
  citizenshipValidation: "Please Select the Citizenship",
  detailPhoneNumber: "Please Enter the Phone Number",
  SSNValidation: "Please Enter the SSN",
  Address1Validation: "Please Enter the current Address",
  Address2Validation: "Please Enter the Permenant Address",
  detailCity: "Please Enter the City",
  detailZIp: "Please Enter the Zip Code",

  //company variables
  companyNameLabel: "Company Name",
  companyEIN: "Company EIN",
  companyPhoneNumber: "Put your phone here",
  companyEmail: "Company Email",
  companyWebsite: "Company Website Url",

  //company validation
  companyNameLabelvalidation: "Please Enter company Name",
  companyEINvalidation: "Please Enter Company EIN",
  companyPhoneNumbervalidation: "Please Enter your phone here",
  companyEmailvalidation: "Please Enter Company Email",
  companyWebsitevalidation: "Please Enter Company Website Url",

  pleaseSelectDate: "Please select Date",
  dateOfBirth: "DATE OF BIRTH",
  resident: "Resident",

  addressLine1Req: "Address Line is required",
  addressLine2Req: "Address Line is required",
  zipReq: "Zip Code is required",
  cityReq: "City is required",
  stateReq: "State is required",

  registerSuccess: "User Registered",
  registerSuccessDesc: "User Registered Successfully.",

  registerError: "Registration Failed",
  emailExists: "Email already exists",

  addedDetails: "Details Updated",
  addedSuccessDesc: "Details Updated Successfully.",

  error: "Error",

  raiseFund: "Raise Fund",
  invest: "Invest",
  MyPortfolio: "My Portfolio",
  MyBusiness: "My Business",
  raiseHeading: "Apply to raise on Fursa",
  raiseDescription:
    "All information submitted in this application is for internal use only and is treated with the utmost confidentiality. Companies may apply to raise with fursa more than once",
  AboutCompany: "About Your Company",
  requestinfo: "All requested information in this section is required.",
  companyTitle: "Legal Name",
  companyDescription:
    "This should be the name your company uses on your website and in the market.",
  cofounderTitle: "Your title",
  cofounderPlaceholder: "Cofounder",
  cofounderDescription: "You official role at the company.",
  websiteTitle: "Website",
  registerBusinessTitle: 'Registered Business Address',
  registerBusinessdesc: 'Please enter registered Business Address of your company',
  DesiredLaunchTitle: 'Desired launch date',
  registerBusinessdesc: 'Please enter registered Business Address of your company',
  websitePlaceholder: "Enter the Company",
  websiteDescription: "Please enter a valid URL that represents your company.",
  industryTitle: "Industry",
  industryPlaceholder: "Enter the Company",
  industryDescription:
    "Choose the industry that best aligns with your business.",
  capitalRaisedTitle: "How much money has your company raised to date?",
  capitalRaisedPlaceholder: "$1,000,000",
  capitalRaisedDescription:
    "The sum total of past financing, including angel or venture capital, loans, grants, or token sales.",
  registerCountryTitle: "Is your company incorporated in the United States?",
  registereCountryDescription:
    "Only companies that are incorporated or formed in the US are eligible to raise via Reg CF. If your company is incorporated outside the US, we still encourage you to apply.",
  productAvailableTitle: "Is your product available (for sale) in market?",
  productAvailableDescription:
    "Only check this box if customers can access, use, or buy your product today.",
  generateRevenueTitle: "Is your company generating revenue?",
  generateRevenueDescription:
    "Only check this box if your company is making money. Please elaborate on revenue and other traction below.",
  pitchdeckTitle: "Pitch deck",
  pitchdeckDescription:
    "Your pitch deck and other application info will be used for internal purposes only. Please make sure this document is publicly accessible. This can be a DocSend, Box, Dropbox, Google Drive or other link.",
  communitySizeTitle: "What's the rough size of your community?",
  communitySizeDescription:
    "Include your email list, social media following (i.e. Instagram, Discord, Facebook, Twitter, TikTok). We’d like to understand the rough size of your current audience",
  additionalInfoTitle: "Additional information",
  additionalinfodesc:
    "The following requested information is optional, but may help us conduct due diligence faster.",
  regCf:
    "My company has run an investment campaign (Reg CF or Reg A+) in the past",
  reCfdesc:
    "If you’ve done this before, certain rules may apply. We are happy to discuss those rules with you.",
  investRaise: "How much would you like to raise on Fursa?",
  investPlaceholder: "$1,000,000",
  investDesc:
    "We encourage you to set a specific target, though your potential total raise will be subject to SEC regulations.",
  companyRunawayTitle:
    "How would you characterize your company's runway (in months)?",
  companyRunawayplaceHolder: "0",
  companyRunawayDesc:
    "Runway is calculated as current cash on hand divided by monthly burn.. (i.e. a company that has $500,000 cash-on-hand, and a net loss of $100,000 per month, has a runway of five months.)",
  personalInfoheading: "Personal information",
  personaInfodesc:
    'Displayed on your profile and across Fursa. Must be your realname.',
  UpdateBtn: 'Update',
  DeleteBtn: 'Delete Account',
  RepublicUs: 'Fursa is required by U.S. financial laws to verify the identity of all investors.',
  identitydesc: 'Your identity information will be used to verify your identity. It will be stored securely and will not be shared without your permission, except as required by law. For additional information visit the',
  PostalCode: 'Postal code',
  adminEmail: ["shaharyar.khan@globewyze.com"]
};
